import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { steps } from "../service";
import styles from "./steps.module.scss";
import { useTranslation } from "react-i18next";

export default function Steps() {
  const controls1 = useAnimation();
  const [ref1, inView1] = useInView();
  const { t } = useTranslation();

  useEffect(() => {
    if (inView1) {
      controls1.start("visible");
    }
  }, [controls1, inView1]);

  const container = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.2,
      },
    },
  };

  const itemVariantVertical = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate={controls1}
      ref={ref1}
      className={styles.steps}
    >
      <div className="container">
        <h1 className="title">{t("howWork")}</h1>
      </div>
      <div
        className={styles.wrapper}
        style={{
          backgroundImage: `url(${steps.line})`,
        }}
      >
        <img src={steps.line} className={styles.line} width="100%" alt="line" />
        {steps.childs.map((item, i) => (
          <motion.div
            variants={itemVariantVertical}
            className={styles.step}
            key={i}
          >
            <img src={item.img} alt={item.title} />
            <div className={styles.wrap_step}>
              <h3>{t(`${item.title}`)}</h3>
              <span>{t(`${item.about}`)}</span>
            </div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
}
