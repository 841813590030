import React from "react";
import cls from "./clients.module.scss";
import Marquee from "react-marquee-slider";
import { clients } from "../service";
import { useTranslation } from "react-i18next";

const Clients = ({ id }) => {
  const { t } = useTranslation();

  const photos = clients.pictures;

  let photos_1container = photos.slice(
    0,
    photos.length % 2 === 0 ? photos.length / 2 : (photos.length + 1) / 2
  );
  let photos_2container = photos.slice(
    photos.length % 2 === 0 ? photos.length / 2 : (photos.length + 1) / 2 - 1,
    photos.length
  );

  return (
    <section className={`${cls.galleryMarqueeSection}`} id={id}>
      <div className="container py-4">
        <h1 className="title">{t(`${clients.title}`)}</h1>
      </div>
      <div
        className={`${cls.marqueeContent}`}
        style={{
          marginTop: "60px",
        }}
      >
        {photos.length > 0 ? (
          <Marquee key="1" velocity={10}>
            {photos_1container.map((item, id) => (
              <div
                key={id}
                className={cls.client_item}
                style={{
                  padding: "0 20px",
                }}
              >
                <img
                  src={item}
                  key={`1marquee-example-people-${id}`}
                  style={{
                    marginLeft: "50px",
                  }}
                  className={cls.marqueeImg}
                  alt="clients"
                />
              </div>
            ))}
          </Marquee>
        ) : (
          ""
        )}
      </div>
      <div className={`${cls.marqueeContent}`}>
        {photos.length > 0 ? (
          <Marquee key="2" velocity={10} direction="clockwise">
            {photos_2container.map((item, id) => (
              <div
                key={id}
                className={cls.client_item}
                style={{
                  padding: "0 20px",
                }}
              >
                <img
                  src={item}
                  key={`marquee-example-people-${id + 7}`}
                  className={cls.marqueeImg}
                  style={{
                    marginLeft: "7px",
                    marginRight: "43px",
                  }}
                  alt="clients"
                />
              </div>
            ))}
          </Marquee>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

export default Clients;
