import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import styles from "./services.module.scss";
import group from "../../images/Group.svg";
import iphone from "../../images/iPhone-X.svg";
import git from "../../images/Git.svg";
import layers from "../../images/Layers.svg";
import settings from "../../images/Settings.svg";
import display from "../../images/Display.svg";
import { useTranslation } from "react-i18next";

export default function Services({ id }) {
  const controls = useAnimation();
  const { t } = useTranslation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const container = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.2,
      },
    },
  };

  const itemVariant = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <div className={styles.services} id={id}>
      <motion.div
        variants={container}
        initial="hidden"
        animate={controls}
        ref={ref}
        className="container"
      >
        <h1 className="title">{t("ourService")}</h1>
        <div className={styles.wrapper}>
          <motion.div variants={itemVariant} className={styles.card}>
            <img src={group} alt="Group icon" />
            <h3>{t("ourCommand")}</h3>
          </motion.div>
          <motion.div variants={itemVariant} className={styles.card}>
            <img src={iphone} alt="Group icon" />
            <h3>{t("mobile")}</h3>
          </motion.div>
          <motion.div variants={itemVariant} className={styles.card}>
            <img src={git} alt="Group icon" />
            <h3>{t("erp")}</h3>
          </motion.div>
          <motion.div variants={itemVariant} className={styles.card}>
            <img src={layers} alt="Group icon" />
            <h3>{t("ui")}</h3>
          </motion.div>
          <motion.div variants={itemVariant} className={styles.card}>
            <img src={settings} alt="Group icon" />
            <h3>{t("optimisation")}</h3>
          </motion.div>
          <motion.div variants={itemVariant} className={styles.card}>
            <img src={display} alt="Group icon" />
            <h3>{t("consulting")}</h3>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}
