import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import styles from "./project.module.scss";
import { useTranslation } from "react-i18next";

export default function Project({ data, id }) {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const controls1 = useAnimation();
  const [ref1, inView1] = useInView();
  const { t } = useTranslation();

  useEffect(() => {
    if (inView1) {
      controls1.start("visible");
    }
  }, [controls1, inView1]);
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const container = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.2,
      },
    },
  };

  const itemVariant = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const imgVariant = {
    hidden: {
      scale: 0,
      opacity: 0,
    },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.2,
        delay: 0.1,
      },
    },
  };

  return (
    <div className={styles.project} id={id}>
      <div className="container">
        <div className={styles.wrapper}>
          <motion.div
            variants={container}
            initial="hidden"
            animate={controls}
            ref={ref}
            className={styles.inner}
          >
            <div className={styles.title}>
              <img src={data.brand} alt={data.title} className={styles.brand} />
              <span
                className={styles.projectType}
                style={{
                  backgroundColor: data.type.background,
                  color: data.type.color,
                }}
              >
                <img src={data.type.icon} alt={data.type.name} />
                {data.type.name}
              </span>
            </div>
            <div className={`${styles.inner} ${styles.respon}`}>
              <div className={styles.imgWrapper}>
                <motion.img
                  src={data.image}
                  alt={data.title}
                  variants={imgVariant}
                  animate={controls}
                  initial="hidden"
                  ref={ref1}
                />
              </div>
            </div>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: t(`${data.description}`) }}
            />
            <div className={styles.services}>
              <h3 className={styles.serviceTitle}>{t("do")}</h3>
              <div className={styles.serviceWrapper}>
                {data.services.map((item, index) => (
                  <motion.div
                    variants={itemVariant}
                    key={index}
                    className={styles.card}
                  >
                    <img src={item.icon} alt={item.name} />
                    <span className={styles.name}>{item.name}</span>
                  </motion.div>
                ))}
              </div>
            </div>
          </motion.div>
          <div className={`${styles.inner} ${styles.desktop}`}>
            <div className={styles.imgWrapper}>
              <motion.img
                src={data.image}
                alt={data.title}
                variants={imgVariant}
                animate={controls}
                initial="hidden"
                ref={ref1}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
