import React from "react";
import Hero from "../components/hero/hero";
import Layout from "../components/layout/layout";
import OurTeam from "../components/ourTeam/ourTeam";
import SEO from "../components/seo";
import Services from "../components/services/services";
import ServiceSection from "../components/serviceSection/serviceSection";
import {
  mobileDev,
  uxui,
  erpSystems,
  optimization,
  delever,
  smsuz,
  goodzone,
  // najotTalim,
  iman,
  // voxe,
  steps,
} from "../components/service";
import Consulting from "../components/consulting/consulting";
import Tools from "../components/tools/tools";
import Project from "../components/project/project";
import Steps from "../components/steps/steps";
import Contact from "../components/contact/contact";
import Clients from "../components/clients";
import { useTranslation } from "react-i18next";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <Services id="directs" />
    <OurTeam id="team" />
    <div id="services">
      <ServiceSection data={mobileDev} />
      <ServiceSection data={erpSystems} />
      <ServiceSection data={uxui} compress={true} />
      <ServiceSection data={optimization} />
      <Consulting />
    </div>
    <Tools id="tools" />
    <Clients id="clients" />
    <div id="portfolio">
      <Project data={delever} id="delever" />
      <Project data={smsuz} id="smsuz" />
      <Project data={goodzone} id="goodzone" />
      {/* <Project data={najotTalim} id='najot' /> */}
      <Project data={iman} id="iman" />
      {/* <Project data={voxe} id='voxe' /> */}
    </div>
    <Steps data={steps} />
    <Contact />
  </Layout>
);

export default IndexPage;
