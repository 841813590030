import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  root: {
    '& .MuiFormLabel-root.MuiInputLabel-outlined': {
      color: '#878787',
    },
    '& .MuiOutlinedInput-root': {
      marginBottom: '24px',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#B4BEC8',
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#B4BEC8',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#B4BEC8',
    },
    '& .MuiOutlinedInput-input': {
      color: '#B4BEC8',
      '&::placeholder': {
        color: '#B4BEC8',
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
      color: '#1D1A2F',
    },
    '& .MuiInputLabel-outlined': {
      color: '#1D1A2F',
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: '#1D1A2F',
    },
    '& .MuiOutlinedInput-root .MuiInputAdornment-root .MuiButtonBase-root.MuiIconButton-root': {
      color: '#B4BEC8',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiInputAdornment-root .MuiButtonBase-root.MuiIconButton-root': {
      color: '#B4BEC8',
    },
    '&  .MuiInputLabel-asterisk': {
      display: 'none',
    },
  },
}))
