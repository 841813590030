import React, { useEffect } from "react";
import styles from "./serviceSection.module.scss";
import bgPattern from "../../images/bg_pattern.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

export default function ServiceSection({ data, compress = false }) {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const controls1 = useAnimation();
  const [ref1, inView1] = useInView();
  const { t } = useTranslation();

  useEffect(() => {
    if (inView1) {
      controls1.start("visible");
    }
  }, [controls1, inView1]);
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const container = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.15,
      },
    },
  };

  const itemVariant = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const imgVariant = {
    hidden: {
      scale: 0,
      opacity: 0,
    },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.3,
        delay: 0.1,
      },
    },
  };

  return (
    <div className={styles.serviceSection} id={data.id}>
      <div className="container">
        <h1 className="title">{t(`${data.title}`)}</h1>
        <motion.div
          variants={container}
          initial="hidden"
          animate={controls}
          ref={ref}
          className={styles.wrapper}
        >
          <div className={styles.serviceBlock}>
            <p>{t(`${data.description}`)}</p>
            <div className={styles.iconWrapper}>
              {data.services.map((item, index) => (
                <motion.div
                  variants={itemVariant}
                  key={index}
                  className={styles.icon}
                >
                  <img src={item.icon} alt={item.name} />
                  <span className={styles.name}>{item.name}</span>
                </motion.div>
              ))}
            </div>
            {data.techstack.length > 0 && (
              <div className={styles.techstack}>
                <h3 className={styles.title}>{t("texnologic")}</h3>
                <div className={styles.techWrapper}>
                  {data.techstack.map((item, index) => (
                    <motion.div
                      variants={itemVariant}
                      key={index}
                      className={styles.tech}
                    >
                      <img
                        src={item.icon}
                        alt={item.name}
                        className={item.name === "Lottie" ? styles.lottie : ""}
                      />
                      <span className={styles.name}>{item.name}</span>
                    </motion.div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className={styles.serviceBlock}>
            <div className={styles.imgWrapper}>
              <motion.img
                src={bgPattern}
                alt="Background pattern"
                className={styles.bgPattern}
              />
              <motion.img
                variants={imgVariant}
                animate={controls}
                initial="hidden"
                ref={ref1}
                src={data.image}
                alt={data.title}
                className={styles.serviceImg}
              />
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
