import React from "react";
import styles from "./consulting.module.scss";
import consulting from "../../images/consulting.svg";
import frontend from "../../images/frontend.svg";
import backend from "../../images/backend.svg";
import architecture from "../../images/architecture.svg";
import devops from "../../images/devops.svg";
import uxuiDesign from "../../images/uxuiDesign.svg";
import tester from "../../images/tester.svg";
import { useTranslation } from "react-i18next";

export default function Consulting() {
  const { t } = useTranslation();

  return (
    <div className={styles.serviceSection} id="consulting">
      <div className="container">
        <h1 className="title">{t("consulting")}</h1>
        <div className={styles.wrapper}>
          <div className={styles.serviceBlock}>
            <p>{t("consultingTxt")}</p>
            <div className={styles.iconWrapper}>
              <div className={styles.icon}>
                <img src={frontend} alt="Frontend" />
                <span className={styles.name}>Frontend</span>
              </div>
              <div className={styles.icon}>
                <img src={backend} alt="Backend" />
                <span className={styles.name}>Backend</span>
              </div>
              <div className={styles.icon}>
                <img src={architecture} alt="Architecture" />
                <span className={styles.name}>Architecture</span>
              </div>
              <div className={styles.icon}>
                <img src={devops} alt="Devops" />
                <span className={styles.name}>DevOps</span>
              </div>
              <div className={styles.icon}>
                <img src={uxuiDesign} alt="UX/UI" />
                <span className={styles.name}>UX/UI</span>
              </div>
              <div className={styles.icon}>
                <img src={tester} alt="Testing" />
                <span className={styles.name}>QA</span>
              </div>
            </div>
          </div>
          <div className={styles.serviceBlock}>
            <div className={styles.imageWrapper}>
              <img src={consulting} alt="Consulting" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
