import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

import { useInView } from "react-intersection-observer";
import styles from "./tools.module.scss";
import promotheus from "../../images/tech/promotheus.svg";
// import sentry from '../../images/tech/sentry.svg'
// import jenkins from '../../images/tech/jenkins.svg'
import gitlab from "../../images/tech/gitlab.svg";
import kotlin from "../../images/tech/kotlin.svg";
import android from "../../images/tech/android.svg";
import java from "../../images/tech/java.svg";
import postgresql from "../../images/tech/postgresql.svg";
import grafana from "../../images/tech/grafana.svg";
import gcp from "../../images/tech/gcp.svg";
import digitalocean from "../../images/tech/digitalocean.svg";
import aws from "../../images/tech/aws.svg";
import kubernetes from "../../images/tech/kubernetes.svg";
import elk from "../../images/tech/elk.svg";
import mongodb from "../../images/tech/mongodb.svg";
import youtrack from "../../images/tech/youtrack.svg";
import cassandra from "../../images/tech/cassandra.svg";
import azure from "../../images/tech/azure.svg";
import appium from "../../images/tech/appium.svg";
import terraform from "../../images/tech/terraform.svg";
import bitbucket from "../../images/tech/bitbucket.svg";
import loki from "../../images/tech/loki.svg";
import apple from "../../images/tech/ios.svg";
import swift from "../../images/tech/swift.svg";
import flutter from "../../images/tech/flutter.svg";
import nextjs from "../../images/tech/nextjs.svg";
import reactjs from "../../images/tech/reactjs.svg";
import javascript from "../../images/tech/javascript.svg";
import balsamic from "../../images/tech/balsamic.svg";
import invision from "../../images/tech/invision.svg";
import figma from "../../images/tech/figma.svg";
import docker from "../../images/tech/docker.svg";
// import cplus from '../../images/tech/cplus.svg'
import python from "../../images/tech/python.svg";
import photoshop from "../../images/tech/photoshop.svg";

import nodejs from "../../images/tech/nodejs.svg";
import php from "../../images/tech/php.svg";
import golang from "../../images/tech/golang.svg";

import vuejs from "../../images/tech/vuejs.svg";
import principle from "../../images/tech/principle.svg";
import sketch from "../../images/tech/sketch.svg";
import adobe from "../../images/tech/adobe.svg";
// import ansible from '../../images/tech/ansible.svg'
import cypress from "../../images/tech/cypress.svg";
import jmeter from "../../images/tech/jmeter.svg";
import sass from "../../images/tech/sass.svg";
import zeplin from "../../images/tech/zeplin.svg";
import material from "../../images/tech/material.svg";
import antd from "../../images/tech/antd.svg";
// import kibana from '../../images/tech/kibana.svg'
import illustrator from "../../images/tech/illustrator.svg";
import gatsby from "../../images/tech/gatsby.png";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import StyledCheckbox from "../styledCheckbox";
import { useTranslation } from "react-i18next";

const data = [
  {
    icon: promotheus,
    type: "devops",
    name: "Promotheus",
    order: 1,
  },
  {
    icon: loki,
    type: "devops",
    name: "Loki",
    order: 2,
  },
  {
    icon: bitbucket,
    type: "devops",
    name: "Bitbucket",
    order: 3,
  },
  {
    icon: gitlab,
    type: "devops",
    name: "Gitlab",
    order: 4,
  },
  {
    icon: kotlin,
    type: "mobile",
    name: "Kotlin",
    order: 5,
  },
  {
    icon: android,
    type: "mobile",
    name: "Android",
    order: 6,
  },
  {
    icon: java,
    type: "mobile",
    name: "Java",
    order: 7,
  },

  {
    icon: azure,
    type: "infrastructure",
    name: "Azure",
    order: 8,
  },

  {
    icon: gcp,
    type: "infrastructure",
    name: "GCP",
    order: 9,
  },
  {
    icon: digitalocean,
    type: "infrastructure",
    name: "DigitalOcean",
    order: 10,
  },
  {
    icon: aws,
    type: "infrastructure",
    name: "AWS",
    order: 11,
  },
  {
    icon: kubernetes,
    type: "infrastructure",
    name: "Kubernetes",
    order: 12,
  },
  {
    icon: elk,
    type: "devops",
    name: "ELK",
    order: 13,
  },
  {
    icon: grafana,
    type: "devops",
    name: "Grafana",
    order: 14,
  },
  {
    icon: terraform,
    type: "devops",
    name: "Terraform",
    order: 15,
  },
  {
    icon: apple,
    type: "mobile",
    name: "IOS",
    order: 16,
  },
  {
    icon: swift,
    type: "mobile",
    name: "Swift",
    order: 17,
  },
  {
    icon: flutter,
    type: "mobile",
    name: "Flutter",
    order: 18,
  },
  {
    icon: nextjs,
    type: "frontend",
    name: "Next.JS",
    order: 19,
  },
  {
    icon: reactjs,
    type: "frontend",
    name: "React.JS",
    order: 20,
  },
  {
    icon: javascript,
    type: "frontend",
    name: "Javascript",
    order: 21,
  },
  {
    icon: balsamic,
    type: "design",
    name: "Balsamic",
    order: 22,
  },
  {
    icon: invision,
    type: "design",
    name: "Invision",
    order: 23,
  },
  {
    icon: figma,
    type: "design",
    name: "Figma",
    order: 24,
  },
  {
    icon: docker,
    type: "devops",
    name: "Docker",
    order: 25,
  },

  {
    icon: postgresql,
    type: "backend",
    name: "PostgreSQL",
    order: 48,
  },
  {
    icon: python,
    type: "backend",
    name: "Python",
    order: 27,
  },
  {
    icon: nodejs,
    type: "backend",
    name: "Node.JS",
    order: 28,
  },
  {
    icon: php,
    type: "backend",
    name: "PHP",
    order: 29,
  },
  {
    icon: golang,
    type: "backend",
    name: "GO",
    order: 30,
  },
  {
    icon: antd,
    type: "frontend",
    name: "Ant Design",
    order: 31,
  },
  {
    icon: gatsby,
    type: "frontend",
    name: "Gatsby.JS",
    order: 32,
  },
  {
    icon: vuejs,
    type: "frontend",
    name: "Vue.JS",
    order: 33,
  },
  {
    icon: principle,
    type: "design",
    name: "Principle",
    order: 34,
  },
  {
    icon: sketch,
    type: "design",
    name: "Sketch",
    order: 35,
  },
  {
    icon: adobe,
    type: "design",
    name: "Adobe suite",
    order: 36,
  },
  {
    icon: youtrack,
    type: "testing",
    name: "YouTrack",
    order: 37,
  },
  {
    icon: appium,
    type: "testing",
    name: "Appium",
    order: 50,
  },
  {
    icon: cypress,
    type: "testing",
    name: "Cypress",
    order: 39,
  },
  {
    icon: jmeter,
    type: "testing",
    name: "Jmeter",
    order: 40,
  },
  {
    icon: mongodb,
    type: "backend",
    name: "MongoDB",
    order: 40,
  },
  {
    icon: cassandra,
    type: "backend",
    name: "Cassandra",
    order: 40,
  },

  {
    icon: sass,
    type: "frontend",
    name: "Sass",
    order: 45,
  },
  {
    icon: material,
    type: "frontend",
    name: "Material UI",
    order: 47,
  },

  {
    icon: zeplin,
    type: "design",
    name: "Zeplin",
    order: 46,
  },
  {
    icon: illustrator,
    type: "design",
    name: "Illustrator",
    order: 47,
  },
  {
    icon: jmeter,
    type: "design",
    name: "Lottie",
    order: 47,
  },
  {
    icon: photoshop,
    type: "design",
    name: "Photoshop",
    order: 47,
  },
];

export default function Tools({ id }) {
  const [selectedValue, setSelectedValue] = useState("all");
  const controls1 = useAnimation();
  const [ref1, inView1] = useInView();
  const { t } = useTranslation();

  useEffect(() => {
    if (inView1) {
      controls1.start("visible");
    }
  }, [controls1, inView1]);
  const container = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.1,
      },
    },
  };

  const itemVariantVertical = {
    hidden: { y: 20, opacity: 0.3 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const itemVariantHorizontal = {
    hidden: { x: 20, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
    },
  };

  const handleChange = (event) => {
    if (event.target.name === selectedValue) {
      setSelectedValue("all");
    } else {
      setSelectedValue(event.target.name);
    }
    console.log("event => ", event.target.name);
  };

  return (
    <div className={styles.tools} id={id}>
      <motion.div
        className="container"
        variants={container}
        initial={"hidden"}
        animate={controls1}
        ref={ref1}
      >
        <h1 className="title">{t("tools")}</h1>
        <div className={styles.techLegend}>
          <motion.span variants={itemVariantHorizontal}>
            <FormControlLabel
              control={
                <StyledCheckbox
                  name="frontend"
                  checked={selectedValue === "frontend"}
                  onChange={handleChange}
                />
              }
              label={"Frontend"}
            />
          </motion.span>
          <motion.span variants={itemVariantHorizontal}>
            <FormControlLabel
              control={
                <StyledCheckbox
                  name="backend"
                  checked={selectedValue === "backend"}
                  onChange={handleChange}
                />
              }
              label={"Backend"}
            />
          </motion.span>

          <motion.span variants={itemVariantHorizontal}>
            <FormControlLabel
              control={
                <StyledCheckbox
                  name="devops"
                  checked={selectedValue === "devops"}
                  onChange={handleChange}
                />
              }
              label={"Devops"}
            />
          </motion.span>
          <motion.span variants={itemVariantHorizontal}>
            <FormControlLabel
              control={
                <StyledCheckbox
                  name="testing"
                  checked={selectedValue === "testing"}
                  onChange={handleChange}
                />
              }
              label={"Testing"}
            />
          </motion.span>
          <motion.span variants={itemVariantHorizontal}>
            <FormControlLabel
              control={
                <StyledCheckbox
                  name="design"
                  checked={selectedValue === "design"}
                  onChange={handleChange}
                />
              }
              label={"UX/UI"}
            />
          </motion.span>
          <motion.span variants={itemVariantHorizontal}>
            <FormControlLabel
              control={
                <StyledCheckbox
                  name="infrastructure"
                  checked={selectedValue === "infrastructure"}
                  onChange={handleChange}
                />
              }
              label={"Infrastructure"}
            />
          </motion.span>
          <motion.span variants={itemVariantHorizontal}>
            <FormControlLabel
              control={
                <StyledCheckbox
                  name="mobile"
                  checked={selectedValue === "mobile"}
                  onChange={handleChange}
                />
              }
              label={"Mobile"}
            />
          </motion.span>
          {/* <motion.span variants={itemVariantHorizontal}>
            <FormControlLabel
              control={
                <StyledCheckbox
                  name='pm'
                  checked={selectedValue === 'pm'}
                  onChange={handleChange}
                />
              }
              label={'PM'}
            />
          </motion.span> */}
        </div>
        <div className={styles.techContainer}>
          {data.map((item, index) => (
            <div
              key={index}
              variants={itemVariantVertical}
              className={`${styles.techItem} ${selectedValue} tech_item_${item.type}`}
            >
              <img src={item.icon} alt={item.name} />
              <span>{item.name}</span>
            </div>
          ))}
        </div>
      </motion.div>
    </div>
  );
}
