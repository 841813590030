import React, { useState, useRef, useEffect } from 'react'
import styles from './contact.module.scss'
import { useStyles } from '../inputStyle'
import { TextField } from '@material-ui/core'
import { YouTube, Instagram, Facebook, Twitter, Telegram } from '@material-ui/icons'
import location from '../../images/contact/location.svg'
import phone from '../../images/contact/phone.svg'
import mail from '../../images/contact/mail.svg'
import axios from 'axios'
import { NotificationContainer, NotificationManager } from 'react-notifications'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'

export default function Contact() {
  const { t } = useTranslation()
  const classes = useStyles()
  const [openModal, setCloseModal] = useState(false)
  const [data, setData] = useState({
    name: '',
    phone: '',
    text: ''
  })

  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [emailDirty, setEmailDirty] = useState(false)
  const [nameDirty, setNameDirty] = useState(false)
  const [emailError, setEmailError] = useState(t('valid'))
  const [nameError, setNameError] = useState(t('valid'))

  const blurHandler = (e) => {
    switch (e.target.name) {
      case 'phone':
        if (data.phone.length == 0) {
          setEmailDirty(true)
        } else {
          setEmailDirty(false)
        }
        break
      case 'name':
        if (data.name.length == 0) {
          setNameDirty(true)
        } else {
          setNameDirty(false)
        }
        break
    }
  }

  const wrapperRef = useRef(null)
  useOutsideCloseMenu(wrapperRef)

  function useOutsideCloseMenu(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setCloseModal(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)

      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value })
  }

  const hanldeSubmit = (event) => {
    event.preventDefault()
    if (data.phone.length === 0 && data.name.length === 0) {
      setEmailDirty(true)
      setNameDirty(true)
    } else {
      axios
        .post('https://mail.udevs.io/send-email', {
          ...data
        })
        .then((res) => {
          console.log(res)
          if (res.status === 200) setCloseModal(true)
          // NotificationManager.success('Мы с вами свяжемся!', 'Заявка принята')
        })
        .catch((err) => {
          NotificationManager.warning('Произошла ошибка', 'Повторите еще раз')
        })
    }
  }

  return (
    <div className={styles.contact} id='contact'>
      <div className='container'>
        <h1 className='title'>{t('callWe')}</h1>
        <div className={styles.contactWrapper}>
          <h3 className={styles.title}>{t('leavePost')}</h3>
          <div className={styles.wrapper}>
            <div className={styles.inner}>
              <form onSubmit={hanldeSubmit}>
                {nameDirty && nameError && (
                  <div
                    style={{
                      color: 'red',
                      marginBottom: '10px',
                      fontSize: '16px'
                    }}
                  >
                    {nameError}
                  </div>
                )}
                <TextField
                  type='text'
                  label={t('name')}
                  variant='outlined'
                  name='name'
                  value={data.name}
                  className={classes.root}
                  fullWidth
                  required
                  onChange={handleChange}
                  onBlur={(e) => blurHandler(e)}
                />
                {emailDirty && emailError && (
                  <div
                    style={{
                      color: 'red',
                      marginBottom: '10px',
                      fontSize: '16px'
                    }}
                  >
                    {emailError}
                  </div>
                )}
                <TextField
                  type='email'
                  label={t('email')}
                  variant='outlined'
                  name='phone'
                  value={data.phone}
                  className={classes.root}
                  fullWidth
                  required
                  onChange={handleChange}
                  onBlur={(e) => blurHandler(e)}
                />
                <TextField
                  type='text'
                  label={t('yourProject')}
                  variant='outlined'
                  name='text'
                  value={data.text}
                  className={classes.root}
                  fullWidth
                  multiline
                  rows={4}
                  onChange={handleChange}
                />
                <div className='call-to-action'>
                  <button type='submit' className={styles.sendBtn}>
                    {t('send')}
                  </button>
                </div>
              </form>
            </div>
            <div className={styles.inner}>
              <ul className={styles.list}>
                <li className={styles.listItem}>
                  <a
                    href='https://yandex.com/map-widget/v1/?um=constructor%3A7ca26e40b93ebc46d15bad846f85d1b7e00f167fb123e4d14ca535dfc64a7c41&amp;source=constructor'
                    className={styles.itemLink}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    <img src={location} alt='Location' />
                    Ташкент,  Юнусабадский район, проспект Амира Темура, 129Б
                  </a>
                </li>
                <li className={styles.listItem}>
                  <a href='tel:+998336600999' className={styles.itemLink}>
                    <img src={phone} alt='Phone' />
                    +998 33 66 00 999
                  </a>
                </li>
                <li className={styles.listItem}>
                  <a
                    href='mailto:azizbek.b@udevs.io'
                    className={styles.itemLink}
                  >
                    <img src={mail} alt='Email icon' />
                      azizbek.b@udevs.io
                  </a>
                </li>
                <li className={styles.listItem}>
                  <a
                    href='https://t.me/azizbekbakhodirov'
                    className={styles.itemLink + ' ' + 'telegram'}
                    target='_blank'
                  >
                   <Telegram style={{ color: '1B5BF7', marginRight: 6 }}/>
                      <strong style={{ color: '#1B5BF7'}}> t.me/azizbekbakhodirov</strong>
                  </a>
                </li>
              </ul>
              <div className={styles.social}>
                <a
                  href='/'
                  className={`${styles.youtube_link}`}
                  target='_blank'
                >
                  <YouTube />
                </a>
                <a
                  href='/'
                  target='_blank'
                  className={`${styles.instagram_link}`}
                >
                  <Instagram />
                </a>
                <a
                  href='/'
                  target='_blank'
                  className={`${styles.facebook_link}`}
                >
                  <Facebook />
                </a>
                <a
                  href='/'
                  target='_blank'
                  className={`${styles.twitter_link}`}
                >
                  <Twitter />
                </a>
              </div>
              <div
                className={styles.dialog}
                style={{
                  opacity: openModal ? 1 : 0,
                  zIndex: openModal ? '999999999' : '-3'
                }}
              >
                <div className={styles.dialog_wrapper} ref={wrapperRef}>
                  <CheckCircleIcon />
                  <h3>{t('offerEnd')}</h3>
                  <p>{t('seeYou')}</p>
                  <span
                    className={styles.close}
                    onClick={() => {
                      setCloseModal(false)
                    }}
                  >
                    <CloseIcon />
                  </span>
                </div>
              </div>

              <div className={styles.map}>
                <iframe
                  src="https://yandex.com/map-widget/v1/?um=constructor%3A7ca26e40b93ebc46d15bad846f85d1b7e00f167fb123e4d14ca535dfc64a7c41&amp;source=constructor" frameborder="0"
                  width='400px'
                  height='200px'
                  title='Udevs location'
                  frameBorder='0'
                  allowFullScreen=''
                  aria-hidden='false'
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <NotificationContainer />
    </div>
  )


}

