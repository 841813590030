import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import styles from "./ourTeam.module.scss";
import teamIcon from "../../images/teamIcon.svg";
import { useCountUp } from "react-countup";
import { useTranslation } from "react-i18next";

export default function OurTeam({ id }) {
  const { t } = useTranslation();
  const { countUp, start } = useCountUp({
    start: 0,
    end: 100,
    delay: 3,
    duration: 5,
    onReset: () => console.log("Resetted!"),
    onUpdate: () => console.log("Updated!"),
    onEnd: ({ pauseResume }) => {
      document.getElementById("plus").style.opacity = "1";
    },
  });
  const controls_count = useAnimation();
  const [refCount, inViewCount] = useInView({
    triggerOnce: true,
  });
  useEffect(() => {
    if (inViewCount) {
      start();
    }
  }, [controls_count, inViewCount]);

  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const controls1 = useAnimation();
  const [ref1, inView1] = useInView();

  useEffect(() => {
    if (inView1) {
      controls1.start("visible");
    }
  }, [controls1, inView1]);

  const container = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.4,
      },
    },
  };

  const itemVariant = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const imgVariant = {
    hidden: {
      scale: 0,
      opacity: 0,
    },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 0.2,
      },
    },
  };
  return (
    <div className={styles.ourTeam} id={id}>
      <motion.div
        variants={container}
        initial={"hidden"}
        animate={controls}
        className="container"
        ref={ref}
      >
        <h1 className="title">{t("ourCommand")}</h1>
        <div className={styles.wrapper}>
          <div className={styles.inner}>
            <motion.p variants={itemVariant}>{t("teamTxt")}</motion.p>
            <motion.div className={styles.infoWrapper} variants={itemVariant}>
              <div>
                <span className={styles.number} ref={refCount}>
                  {countUp}
                  <span
                    id={`plus`}
                    style={{
                      transition: "0.4s ease-in-out all",
                      opacity: 0,
                    }}
                  >
                    +
                  </span>
                </span>
              </div>
              <p className={styles.title}>{t("detictedTeam")}</p>
            </motion.div>
          </div>
          <div className={styles.inner}>
            <motion.img
              src={teamIcon}
              ref={ref1}
              variants={imgVariant}
              alt="Software development agency"
            />
          </div>
        </div>
      </motion.div>
    </div>
  );
}
