import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import styles from "./hero.module.scss";
import logo from "../../images/logo.svg";
import AnchorLink from "react-anchor-link-smooth-scroll";
import hero from "../../images/hero.svg";
import Typewriter from "typewriter-effect";
import { useTranslation } from "react-i18next";

export default function Hero() {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const { t } = useTranslation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const title = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
    },
  };
  const about = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.3,
      },
    },
  };

  return (
    <div className={styles.hero} id="home">
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.heroInfo}>
            <motion.img
              variants={title}
              initial="hidden"
              animate={controls}
              ref={ref}
              src={logo}
              alt="Udevs"
            />
            <motion.h1
              variants={about}
              initial="hidden"
              animate="visible"
              style={{
                marginBottom: 20,
              }}
            >
              {t("companyType")}
              {/* {t('hello')} */}
            </motion.h1>
            <motion.h1
              style={{
                color: "var(--primary)",
              }}
              variants={about}
              initial="hidden"
              animate="visible"
            >
              <Typewriter
                options={{
                  strings: [
                    t("mobile"),
                    t("erp"),
                    t("ui"),
                    t("optimisation"),
                    t("consulting"),
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </motion.h1>
            <motion.div
              className="call-to-action large"
              variants={about}
              initial="hidden"
              animate="visible"
            >
              <AnchorLink href="/contact">{t("toCall")}</AnchorLink>
            </motion.div>
          </div>
          <div className={styles.heroImage}>
            <img src={hero} alt="Udevs - Software development agency" />
          </div>
        </div>
      </div>
    </div>
  );
}
